.certSection {
  padding: 72px 0;
  width: 100%;
}

.bootstrapWrapper {
  width: 90%;
  margin: 0 auto;
}

.cert {
  display: flex;
  justify-content: center; /* Center the cert section */
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.certBlocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 48%;
  padding: 20px;
  cursor: pointer; /* Add pointer cursor on hover */
}

.certImage img {
  max-width: 100%;
  height: 256px;
  margin-bottom: 20px;
  object-fit: cover; /* Ensure the image fits well within the specified height */
  transition: transform 0.3s ease, filter 0.3s ease;
  border-radius: 18px;
}

.certImage img:hover {
  transform: scale(1.05); /* Slightly increase the size */
  filter: brightness(1.1); /* Increase brightness */
}

.certInfo {
  text-align: center;
}

.labelCN {
  font-size: 18px;
  font-weight: 600;
  color: #4caf50;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.labelCN::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150%;
  width: 150%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: skewX(-20deg);
  transition: 0.4s;
  animation: light-sweep 2s infinite;
}

@keyframes light-sweep {
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
}

.labelCN:hover:before {
  left: 100%;
}

.certTitle {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.certSubTitle {
  font-size: 14px;
  color: #666;
  display: inline-block;
  margin-right: 10px;
}

.prize {
  display: flex;
  justify-content: center; /* Center the prize section */
  padding: 20px 0;
}

.prizeBlocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
  cursor: pointer; /* Add pointer cursor on hover */
  height: 259px; 
}
.prizeImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 150px; /* Đặt chiều cao cố định hoặc lớn hơn tùy thiết kế */
  max-width: 100%;
  height: 100%;
}

.prizeImage {
  display: flex;
  justify-content: center; /* Center the image within its container */
  margin-bottom: 15px;
  max-height: 128px; /* Set max height to 128px */
  position: relative;
  overflow: hidden;
}

.prizeImage img {
  max-width: 100%;
  height: auto;
  max-height: 128px; /* Ensure the image does not exceed 128px in height */
  border-radius: 10px;
  transition: transform 0.3s ease;
  border-radius: 18px;
}

.prizeImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150%;
  width: 150%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: skewX(-20deg);
  transition: 0.4s;
  animation: light-sweep1 2s infinite;
}


@keyframes light-sweep1{
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
}

.prizeImage img:hover {
  transform: scale(1.05); /* Slightly increase the size */
  filter: brightness(1.1); /* Increase brightness */
}

.prizeInfo {
  margin-top: auto; /* Đẩy phần thông tin xuống đáy */
  text-align: center;
}

.top {
  font-size: 16px;
  font-weight: bold;
  color: #4caf50;
  margin-bottom: 5px;
}
.sponsor {
  font-size: 16px;
  font-weight: bold;
  color: #4caf50;
  margin-bottom: 5px;
  min-height: 24px; /* Đặt chiều cao tối thiểu để các phần tử căn chỉnh đều */
  display: flex;
  align-items: center; /* Canh giữa nội dung theo chiều dọc */
}

.sponsorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  min-height: 40px; /* Đảm bảo các container có cùng độ cao */
  width: 100%;
}

.prizeTitle {
  font-size: 14px;
  color: #333;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .certBlocks {
    width: 100%; /* Make cert blocks take full width on smaller screens */
    margin-bottom: 30px;
  }

  .prize {
    flex-direction: column;
  }

  .prizeBlocks {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .certSection {
    padding: 48px 0;
  }

  .certBlocks {
    padding: 15px;
  }

  .certImage img {
    height: 200px; /* Reduce image height for smaller screens */
  }

  .labelCN {
    font-size: 16px;
  }

  .certTitle {
    font-size: 14px;
  }

  .certSubTitle {
    font-size: 12px;
  }

  .prizeImage {
    max-height: 100px;
  }

  .prizeImage img {
    max-height: 100px;
  }

  .top {
    font-size: 14px;
  }

  .prizeTitle {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .certSection {
    padding: 32px 0;
  }

  .bootstrapWrapper {
    width: 100%;
    padding: 0 10px;
  }

  .certImage img {
    height: 150px;
  }

  .certBlocks {
    padding: 10px;
  }

  .prizeImage img {
    max-height: 80px;
  }
}
